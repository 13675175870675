import { service } from '../CONF.js'

//APP端支付渠道选择
export function channel_selection(type) {
    const data = {
        type: type,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/PayCanal/choosePayList', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//线下支付订单
export function offline_payment_order(type, pname, pay_price, account_num, payee, pay_type, bank) {
    const data = {
        type: type,
        pname: pname,
        pay_price: pay_price,
        account_num: account_num,
        payee: payee,
        pay_type: pay_type,
        bank: bank
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/UnderpayOrder/createPayOrder', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}

//线上支付渠道列表
export function onLineCanal() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post('/main/payment/onLineCanal')
        }))
    })
}

//线上充值
export function onLineRecharge(data) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post('/main/payment/onLineRecharge',{
                ...data,                
            })
        }))
    })
}

export function onLineRecharge1(canal_id, money, need_params) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/main/payment/onLineRecharge',{
                params: {
                    canal_id: canal_id,
                    money: money,
                    need_params: need_params
                },
            })
        }))
    })
}

export function canUsePaymentType() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('main/payCanal/canUsePaymentType',{
                params: {
                    
                },
            })
        }))
    })
}