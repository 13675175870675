<script>
import { channel_selection } from "@/api/recharge";
import { offline_payment_order } from "@/api/recharge";
export default {
  data() {
    return {
      setAt: true, //防止重复提交
      payList: [], //收款列表
      atAccount_num: "", //选中的收款账号
      pname: "", //汇款人
      pay_price: "", //汇款金额
      payee: "", //收款人
      pay_type: "", //汇款方式,无
      bank: "", //支付银行,无
      remark: null,
    };
  },
  components: {
    AlipayTEMPLATE1: () => import("./AlipayTEMPLATE1.vue"),
  },
  methods: {
    //读取支付宝支付信息
    myrecharge() {
      channel_selection("2").then((result) => {
        if (result.data.code == "FAILUE") {
          app.Mint.Toast(result.data.msg);
        } else if (result.data.code == "SUCCESS") {
          this.payList = result.data.result;
          this.yhkWidth(this.payList.length);
          if (this.payList.length > 0) {
            this.YHKselect(this.payList[0]);
            let _this = this;
            setTimeout(function () {
              if (_this.payList.length > 0) {
                let lab = document.getElementById("label0");
                lab.click();
              }
            }, 200);
          }
        }
      });
    },
    send(obj) {
      console.log(obj);
      this.pname = obj.pname;
      this.pay_price = obj.pay_price;
    },
    //提交申请
    commit() {
      if (this.setAt != true) {
        return;
      } else {
        this.setAt = false;
      }
      if (
        this.atAccount_num == "" ||
        this.pname == null ||
        this.pname == undefined
      ) {
        app.Mint.Toast(getWord("fill_payment_account"));
        return;
      } else if (
        this.pname == "" ||
        this.pname == null ||
        this.pname == undefined
      ) {
        app.Mint.Toast(getWord(["fill", "username3"]));
        return;
      } else if (
        this.pay_price == "" ||
        this.pay_price == null ||
        this.pay_price == undefined
      ) {
        app.Mint.Toast(getWord(["fill", "transfer2", "amount_of_betting"]));
        return;
      }
      offline_payment_order(
        "2",
        this.pname,
        this.pay_price,
        this.atAccount_num,
        this.payee,
        this.pay_type,
        this.bank
      ).then((result) => {
        if (result.data.code == "FAILUE") {
          app.Mint.Toast(result.data.msg);
        } else if (result.data.code == "SUCCESS") {
          this.$router.push({ path: "/money/payment_page/" + this.pay_price });
        } else {
          app.Mint.Toast(result.data.msg);
        }
      });
      this.setAt = true;
    },
    yhkWidth(leng) {
      let YHKWid = document.getElementById("YHKWid");
      let num = leng;
      if (parseInt(num) == 0) {
        app.Mint.Toast(getWord("no_support"));
        // }else if(parseInt(num) == 1){
        //     YHKWid.style.width = '6.2rem';
        //     YHKWid.style.overflow = 'hidden';
        //     YHKWid.style.marginLeft = '0.3rem';
        // }else{
        // YHKWid.style.width = (num*6.3)+"rem";
      }
    },
    YHKselect(it) {
      this.payee = it.payee;
      this.atAccount_num = it.account_num;
    },
  },
  created() {
    this.myrecharge();
    this.remark = JSON.parse(
      window.localStorage.getItem("channelsData")
    )[1].underline_remark;
  },
  provide: function () {
    return {
      YHKselect: this.YHKselect,
    };
  },
};
</script>
<template>
  <AlipayTEMPLATE1
    :payList="payList"
    :remark="remark"
    @send="send"
    @commit="commit"
  />
</template>
<style lang="scss" scoped type="text/css">
</style>